export enum Units {
  Imperial = 'Imperial',
  Metric = 'Metric'
}


export enum Pollutant {
  CO2e = 'CO2e',
  CO2 = 'CO2',
  NOx = 'NOx',
  SO2 = 'SO2'
}


// Note that these values should match keys in the color palette.
export enum FuelCategory {
  All = 'all_fuel_types',
  All_Renewable = 'all_renewables',
  All_Other = 'all_others',
  Wind = 'wind',
  Solar = 'solar',
  Hydro = 'hydro',
  Nuclear = 'nuclear',
  Geothermal = 'geothermal',
  Storage = 'storage',
  Storage_Charge = 'storage_charge',
  Storage_Discharge = 'storage_discharge',

  Biomass = 'biomass',
  Wood = 'wood',
  Waste = 'waste',

  All_Fossil = 'all_fossil_fuels',
  Natural_Gas = 'natural_gas',
  Oil = 'petroleum',
  Coal = 'coal',
  Other = 'other',
}


export enum MonthOrSeason {
  All = 'all',

  // Months
  Jan = '1',
  Feb = '2',
  Mar = '3',
  Apr = '4',
  May = '5',
  Jun = '6',
  Jul = '7',
  Aug = '8',
  Sep = '9',
  Oct = '10',
  Nov = '11',
  Dec = '12',

  // Seasons
  Q1 = '1,2,3',
  Q2 = '4,5,6',
  Q3 = '7,8,9',
  Q4 = '10,11,12',

  // MISO Subyears
  SY1 = '12,1,2',
  SY2 = '3,4,5',
  SY3 = '6,7,8',
  SY4 = '9,10,11'
}


export enum MapLevel {
  BAs = 'All BAs',
  LARGE_BAs = 'Large BAs',
  ISOs = 'ISOs',
  MISO = 'MISO',
  Subregions = 'Subregions',
  States = 'States',
  MISO_States = 'MISO States',
  MISO_Counties = 'Counties',
  LRZs = 'LRZs',
  LBAs = 'LBAs',
}


export enum ChartViewMode {
  Emissions = 'Total Emissions',
  Intensity = 'Emissions Intensity',
  Generation = 'Energy Generation'
}


export enum EntityType {
  Plant = 'plant',
  BaRegion = 'ba_region',
  BaSubregion = 'ba_subregion',
  State = 'state',
  County = 'county'
}


export enum Duration {
  Hour = '1h',
  Day = '1d',
  Month = '1M',
  Subyear = '3M',
  Year = '1Y'
}


export enum FuelMapping {
  Simple = 'simple',
  Expanded = 'expanded'
}


export enum EmissionFramework {
  Generated = 'generated',
  Consumed = 'consumed'
}


export enum EmissionAdjustment {
  Unadjusted = 'unadjusted',
  ForElectricity = 'for_electricity',
  ForElectricityAdjusted = 'for_electricity_adjusted',
  Adjusted = 'adjusted'
}


export enum PlantType {
  Planned = 'planned',
  ModelBuilt = 'model_built',
  Existing = 'existing'
}

import React, { useEffect, useMemo, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';

import * as Constants from 'constants/constants';
import { useAppDispatch } from 'modules/store';
import { setUserShouldDoRealtimeTour, } from 'modules/tour/slice';
import './style.css';

const tourCompletionCookieName = 'Singularity.ProjectionsEmissionsMap.tourVersion';

const steps: Step[] = [
  {
    target: 'body',
    placement: 'center',
    content: (
      <div style={{textAlign: 'left'}}>
        <h1 style={{fontSize: '24px'}}><strong>MISO Projections Grid Map</strong></h1>
        <strong>This dashboard visualizes MISO emissions and generation from the US Energy Grid.</strong>
        <p>
          MISO offers this data as a look into their projected changes to the grid and its respective changes in emissions. Generator projections are based upon MISO's <a href='https://www.misoenergy.org/library/#nt=%2Flibrarydoctype%3APlanning%2Fplanningtype%3AResource%20Adequacy&t=10&p=0&s=FileName&sd=asc' target='_blank'>2024 Regional Resource Assessment</a> (RRA).
        </p>
        <p>For more information, take a look at our <a href={Constants.DASHBOARD_DOCS_URL} target='_blank'>technical documentation</a>.</p>
        <p>Click 'Next' for a quick tour.</p>
      </div>
    ),
  },
  {
    target: '.projections-tour--end-date',
    content: `The date picker on this page goes all the way to ${Constants.LATEST_PROJECTIONS_YEAR}.`,
    placement: 'right'
  },
  {
    target: '.tour--start-over',
    content: (
      <div>
        <h5><strong>Done!</strong></h5>
        <br />
        <p>You can click here to replay the walkthrough at any time.</p>
        <p>For more information, check out the <a href='https://help.misoenergy.org/knowledgebase/article/KA-01501/en-us' target='_blank'>MISO Help Center</a> and Singularity's <a href={Constants.DASHBOARD_DOCS_URL} target='_blank'>Open Grid Emissions documentation</a>.</p>
        <p>For more information on the 2024 Regional Resource Assessment, check out the <a href='https://www.misoenergy.org/library/#nt=%2Flibrarydoctype%3APlanning%2Fplanningtype%3AResource%20Adequacy&t=10&p=0&s=FileName&sd=asc' target='_blank'>MISO RRA report</a> and explore additional generator data on <a href='https://juicebox.org/miso/' target='_blank'>MISO's JuiceBox platform</a>.</p>
      </div>
    ),
  },
];


const ProjectionsJoyrideTour = () => {
  const dispatch = useAppDispatch();

  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    // Check if the user already skipped or finished the tour. If so, don't show it to them again.
    const maybeTourVersion = localStorage.getItem(tourCompletionCookieName);
    console.debug('[singularity] Tour cookie:', maybeTourVersion);
    if (maybeTourVersion !== Constants.CURRENT_TOUR_VERSION) {
      console.debug('[singularity] The user hasn\'t done this version of the tour, starting from the beginning.');
      setStepIndex(0);
      setRun(true);
    }

    document.getElementById(Constants.TOUR_BUTTON_ID).onclick = startTour;
  }, []);

  // This restarts the tour from the beginning.
  const startTour = () => {
    console.debug('[singularity] Restarting tour');
    localStorage.setItem(tourCompletionCookieName, 'invalidated');
    setStepIndex(0);
    setRun(true);
  }

  const handleJoyrideCallback = useMemo(() => (data: CallBackProps) => {
    const { action, index, status, type } = data;

    // If the tour is FINISHED or SKIPPED, set the 'run' state to false so the
    // tour can be started again later.
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      console.debug('[singularity] User finished/skipped tour. Saving a cookie to remember.');
      localStorage.setItem(tourCompletionCookieName, Constants.CURRENT_TOUR_VERSION);
      setStepIndex(0);
      setRun(false);
      // Important! Set the global userShouldDoTour so that another button click will restart.
      dispatch(setUserShouldDoRealtimeTour(false));
      return;
    }

    // Always allow the user to step backwards.
    if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV) {
      setStepIndex(index - 1);
    } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
      setStepIndex(index + 1);
    } else if (type === EVENTS.TARGET_NOT_FOUND) {
      console.error('Target for joyride tour not found!');
      setRun(false);
    }
  }, [dispatch]);

  return (
    <div className="joyride-tour">
      <Joyride
        continuous
        hideBackButton
        hideCloseButton
        run={run}
        callback={handleJoyrideCallback}
        scrollToFirstStep
        showProgress
        spotlightClicks
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#0082ca',
            backgroundColor: '#fff',
          },
        }}
      />
    </div>
  );
}


export default ProjectionsJoyrideTour;

import React, { useEffect } from 'react';

import Box from '@mui/material/Box';

import Highcharts, { Options } from 'highcharts';

import { HelpPopup } from 'components/HelpPopup';
import * as Constants from 'constants/constants';
import { ChartViewMode, EmissionFramework, Pollutant } from 'constants/enums';
import { FuelMixValue } from 'constants/interfaces';
import { fuelCategoryPalette } from 'utils/ColorPalette';
import { findLowestCommonOom, formatFuelCategoryName, formatNumber, formatPollutant } from 'utils/utils';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);


var fuelMixChart: Highcharts.Chart = null;
var emissionMixChart: Highcharts.Chart = null;


Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});


interface IFuelMixPanelProps {
  loading: boolean
  waiting: boolean
  fuelMix: FuelMixValue[]
  emissionMix: FuelMixValue[]
  pollutant: Pollutant
  emissionFramework: EmissionFramework
  showDownloadButtons: boolean
  showChartTitles: boolean
  showGenerationMix: boolean
  showEmissionMix: boolean
  barThicknessPx?: number
}


const FuelMixPanel = (props: IFuelMixPanelProps) => {
  // Generates the chart configuration.
  const createPlotOptions = (series: any, viewMode: ChartViewMode) => {
    // Convert emissions to tons!
    if (viewMode === ChartViewMode.Emissions) {
      series = series.map((el: [number, number]) => [el[0], el[1] / 2000])
    }

    const numbers: number[] = series.map((el: any) => el[1]);
    const lowestCommonOom = findLowestCommonOom(numbers, Math.floor(numbers.length / 3));

    const makeTooltipFormatter = (pollutant: Pollutant,
                                  lowestCommonOom: number,
                                  viewMode: ChartViewMode) => {
      return function() {
        const valueFmt = formatNumber(this.y, 2, lowestCommonOom);
        let totalY = 0;
        this.series.data.forEach((el: any) => {
          totalY += el.y;
        });
        const percentage = 100.0 * this.y / totalY;
        return {
          [ChartViewMode.Emissions]: `${this.key}: ${valueFmt} tons ${pollutant} (${percentage.toFixed(1)}%)`,
          [ChartViewMode.Intensity]: `${this.key}: ${valueFmt} lbs ${pollutant} / MWh (${percentage.toFixed(1)}%)`,
          [ChartViewMode.Generation]: `${this.key}: ${valueFmt} MWh (${percentage.toFixed(1)}%)`
        }[viewMode];
      }
    }

    const yAxisTitle = {
      [ChartViewMode.Emissions]: `tons of ${props.pollutant}`,
      [ChartViewMode.Intensity]: `lbs ${props.pollutant} / MWh`,
      [ChartViewMode.Generation]: `MWh`
    }[viewMode];

    // NOTE(milo): Highcharts sets bar padding in terms of x-axis 'units', which are inconvenient
    // if you want multiple bar charts to have consistent spacing. Here, I'm figuring out how to
    // set the chart height and bar spacing (in units) such that we get the desired spacing in px.
    const barThicknessPx = props.barThicknessPx || 20;
    const barSpacingPx = 10;
    const chartPaddingPx = 5;
    const chartGridPaddingPx = 10;
    const stuffBelowChartHeightPx = 42.65;
    const barPaddingAboveBelowPx = 3.3;
    const axisHeightPx = (series.length) * barThicknessPx +
                         (series.length - 1) * barSpacingPx +
                         2 * barPaddingAboveBelowPx;
    const chartHeightPx = axisHeightPx + stuffBelowChartHeightPx + 2*chartGridPaddingPx + 2*chartPaddingPx;
    const barSpacingUnits = barSpacingPx / (axisHeightPx / series.length);

    const generatedOrConsumed = props.emissionFramework === EmissionFramework.Consumed ? 'Consumed' : 'Generated';
    const chartTitle = {
      [ChartViewMode.Generation]: `${generatedOrConsumed} Electricity by Plant Primary Fuel Type`,
      [ChartViewMode.Emissions]: `${generatedOrConsumed} ${props.pollutant} Emissions by Plant Primary Fuel Type`,
      [ChartViewMode.Intensity]: `${generatedOrConsumed} ${props.pollutant} Intensity by Plant Primary Fuel Type`
    }[viewMode];

    return {
      chart: {
        style: {
          fontFamily: Constants.FONT_FAMILY,
          padding: `${chartPaddingPx}px`,
          // chartPaddingPx: 5
        },
        backgroundColor: 'white',
        height: chartHeightPx,
      },
      title: {
        text: ''
      },
      series: [{
        type: 'bar',
        colorByPoint: true,
        colors: series.map((fuelAndValue: any) => fuelCategoryPalette[fuelAndValue[0]]),
        name: viewMode.toString(),
        data: series.map((fuelAndValue: any) => [formatFuelCategoryName(fuelAndValue[0]), fuelAndValue[1]])
      }],
      legend: {
        enabled: false,
      },
      xAxis: [{
        type: 'category',
        reserveSpace: false,
      }],
      yAxis: [{
        title: {
          text: yAxisTitle
        }
      }],
      plotOptions: {
        bar: {
          groupPadding: 0,
          pointPadding: barSpacingUnits,
          pointWidth: barThicknessPx,
          minPointLength: 5
        },
      },
      tooltip: {
        formatter: makeTooltipFormatter(props.pollutant, lowestCommonOom, viewMode)
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: true,
        scale: 4,
        buttons: {
          contextButton: {
            symbol: "menuball",
            menuItems: ["viewFullscreen", "separator", "downloadPNG", "downloadJPEG", "downloadSVG"],
          },
        },
        chartOptions: {
          title: {
            text: chartTitle,
            align: 'left',
          },
          navigator: {
            enabled: false
          },
          scrollbar: {
            enabled: false
          },
          rangeSelector: {
            enabled: false
          },
          chart: {
            style: {
              fontFamily: Constants.FONT_FAMILY,
            },
          }
        },
        fallbackToExportServer: false
      },
    } as Options;
  }

  const formatForBarChart = (fuelMix: FuelMixValue[]) => {
    return fuelMix.map(el => {
      // Convert to tons!
      return [el.fuelCategory, el.value];
    })
    .filter((el: [string, number]) => el[1] !== 0)
    .sort((a: [string, number], b: [string, number]) => b[1] - a[1])
  }

  useEffect(() => {
    fuelMixChart = Highcharts.chart(
      'fuel-mix-chart--generation',
      createPlotOptions(formatForBarChart(props.fuelMix), ChartViewMode.Generation));

    emissionMixChart = Highcharts.chart(
      'fuel-mix-chart--emissions',
      createPlotOptions(formatForBarChart(props.emissionMix), ChartViewMode.Emissions));
  }, [props]);

  const generatedOrConsumed = {
    [EmissionFramework.Generated]: 'Generated',
    [EmissionFramework.Consumed]: 'Consumed'
  }[props.emissionFramework];

  if (fuelMixChart && props.loading) {
    fuelMixChart.showLoading();
  }
  if (emissionMixChart && props.loading) {
    emissionMixChart.showLoading();
  }

  return (
    <Box p={2}>
      {props.showChartTitles ?
        <div>
          <div>
            {`${generatedOrConsumed} Electricity by Plant Primary Fuel Type`}
            <HelpPopup popupContent={
              <React.Fragment>
              Shows the total {generatedOrConsumed.toLowerCase()} electricity from each plant type during the specified time period. For power plants that consume multiple fuels, a primary fuel is assigned based on the annual volume of fuel consumed.
            </React.Fragment>}
            />
          </div>
        </div>: ''
      }
      {props.showGenerationMix ? <div>
        <div id="fuel-mix-chart--generation"/>
      </div>: ''}
      {props.showChartTitles ?
        <div className="row se--row-no-margin">
          <div className="col align-items-center justify-items-center text-center">
            {generatedOrConsumed} {formatPollutant(props.pollutant)} Emissions by Plant Primary Fuel Type
            <HelpPopup popupContent={
              <React.Fragment>
              Shows the total {generatedOrConsumed.toLowerCase()} {formatPollutant(props.pollutant)} emissions from each plant type during the specified time period. For power plants that consume multiple fuels, a primary fuel is assigned based on the annual volume of fuel consumed. In some instance, a fossil fuel powered generator can operate at a plant where most of the electricity production comes from renewable fuels (e.g. an hydroelectric plant with an auxiliary petroleum generator) leading to non-zero emissions.
            </React.Fragment>}
            />
          </div>
        </div> : ''
      }
      {props.showEmissionMix ? <div>
        <div id="fuel-mix-chart--emissions"/>
      </div>: ''}
    </Box>
  );
}


export default FuelMixPanel;

import Box from '@mui/material/Box';
import snakeCase from 'lodash/snakeCase';
import React, { useEffect, useMemo } from 'react';

import { IGetEmissionEventsByFuelCategoryParams, useGetEmissionEventsByFuelCategoryQuery } from 'api/data';
import { formatEntityCodes, formatFuelCategories } from 'api/utils';
import * as Constants from 'constants/constants';
import { EmissionAdjustment, EntityType, MapLevel, MonthOrSeason } from 'constants/enums';
import { CompactEmissionEvent } from 'constants/interfaces';
import { currentAppState, currentMapHistory } from 'modules/app/selectors';
import { IMapState } from 'modules/app/slice';
import { useAppSelector } from 'modules/store';
import { TrackEventNames, tracker } from 'utils/tracker';
import { getBrowserWidth, last } from 'utils/utils';
import { createDownloadFunction, processApiData, queryParamsAreComplete } from './utils';

import { useLocation } from 'react-router-dom';
import FuelMixPanel from './FuelMixPanel';
import SummaryPanel from './SummaryPanel';
import TimeSeriesChart from './TimeSeriesChart';
import './style.css';


const DataView = () => {
  const appState = useAppSelector(currentAppState);
  const mapHistory = useAppSelector(currentMapHistory);
  const mapState = last<IMapState>(mapHistory);
  const location = useLocation();
  const isProjectionsPage = location.pathname.endsWith('/projections');

  const entityType = {
    [MapLevel.BAs]: 'ba_region',
    [MapLevel.LARGE_BAs]: 'ba_region',
    [MapLevel.ISOs]: 'ba_region',
    [MapLevel.MISO]: 'ba_region',
    [MapLevel.Subregions]: 'ba_subregions',
    [MapLevel.States]: 'state',
    [MapLevel.MISO_States]: 'state',
    [MapLevel.MISO_Counties]: 'county',
    [MapLevel.LRZs]: 'ba_subregion',
    [MapLevel.LBAs]: 'ba_subregion',
  }[mapState.mapLevel] as EntityType;

  const queryParams: IGetEmissionEventsByFuelCategoryParams = useMemo(() => ({
    entityType: entityType.toString(),
    entityCodes: formatEntityCodes(entityType, Array.from<string>(mapState.mapSelection)),
    fuelCategories: formatFuelCategories(appState.fuelCategories),
    filterMonths: appState.monthOrSeason !== MonthOrSeason.All ? appState.monthOrSeason.toString() : undefined,
    outputResolution: appState.timeResolution.toString(),
    fuelMapping: appState.fuelMapping.toString(),
    emissionFramework: appState.emissionFramework.toString(),
    emissionAdjustment: isProjectionsPage ? EmissionAdjustment.ForElectricity.toString() : appState.emissionAdjustment.toString(),
    start: appState.queryStartDate.toISOString(),
    end: appState.queryEndDate.toISOString()
  }), [appState.emissionAdjustment, appState.monthOrSeason, appState.timeResolution, appState.fuelMapping, appState.emissionFramework, appState.queryStartDate, appState.queryEndDate, mapState.mapSelection, appState.fuelCategories, entityType, isProjectionsPage]);

  const paramsComplete = queryParamsAreComplete(appState, mapState);

  useEffect(() => {
    if (paramsComplete) {
      tracker.track(TrackEventNames.REQUESTED_DATA, { ...queryParams, entityCodes: queryParams.entityCodes.join(',') });
    }
  }, [queryParams, paramsComplete]);

  const { data, isFetching, isError } = useGetEmissionEventsByFuelCategoryQuery(
    queryParams, { skip: !paramsComplete });

  const seriesByFuelCategory: { [f: string]: CompactEmissionEvent[] } = (paramsComplete && data?.data) || {};
  const processedData = processApiData(seriesByFuelCategory, appState.queryStartDate, appState.queryEndDate, appState.pollutant);

  let mwhByStartDate: { [dt: string]: { [fuelCategory: string]: number } } = {};
  let lbsByStartDate: { [dt: string]: { [fuelCategory: string]: number } } = {};
  Object.entries(seriesByFuelCategory).forEach(([fuelCategory, events]) => {
    events.forEach((e: CompactEmissionEvent) => {
      // Make sure mwh/lbs has an entry for each date and each fuel type.
      if (!mwhByStartDate.hasOwnProperty(e.startDate)) {
        mwhByStartDate[e.startDate] = { total: 0 };
        processedData.fuelCategories.forEach((fuelCategory) => {
          mwhByStartDate[e.startDate][fuelCategory] = 0;
        });
      }
      if (!lbsByStartDate.hasOwnProperty(e.startDate)) {
        lbsByStartDate[e.startDate] = { total: 0 };
        processedData.fuelCategories.forEach((fuelCategory) => {
          lbsByStartDate[e.startDate][fuelCategory] = 0;
        });
      }
      const emissionsThisPollutant = {
        'CO2': e.co2MassLb,
        'SO2': e.so2MassLb,
        'NOx': e.noxMassLb,
        'CO2e': e.co2EMassLb,
      }[appState.pollutant];
      mwhByStartDate[e.startDate][fuelCategory] = e.netElectricityMwh;
      mwhByStartDate[e.startDate].total += e.netElectricityMwh;
      lbsByStartDate[e.startDate][fuelCategory] = emissionsThisPollutant;
      lbsByStartDate[e.startDate].total += emissionsThisPollutant;
    });
  });

  let avgIntensityByStartDate: { [dt: string]: number } = {};
  Object.entries(lbsByStartDate).forEach(([dt, data]) => {
    avgIntensityByStartDate[dt] = lbsByStartDate[dt].total / mwhByStartDate[dt].total;
  });

  let rows: any = [
    [
      'start_date',
      'total_net_generation_mwh',
      `total_${appState.pollutant.toLowerCase()}_mass_tons`,
      `total_${appState.pollutant.toLowerCase()}_intensity_lbs_per_mwh`
    ]
  ];
  processedData.fuelCategories.forEach(fuelCategory => {
    rows[0].push(`${snakeCase(fuelCategory)}_net_generation_mwh`);
    rows[0].push(`${snakeCase(fuelCategory)}_${appState.pollutant.toLowerCase()}_mass_tons`);
    rows[0].push(`${snakeCase(fuelCategory)}_${appState.pollutant.toLowerCase()}_intensity_lbs_per_mwh`);
  });
  Object.entries(lbsByStartDate).forEach(([dt, data]) => {
    let rowThisDate = [
      dt,
      +mwhByStartDate[dt].total.toFixed(2),
      +(lbsByStartDate[dt].total / 2000).toFixed(2),
      +avgIntensityByStartDate[dt].toFixed(2)
    ];
    processedData.fuelCategories.forEach(fuelCategory => {
      rowThisDate.push(+mwhByStartDate[dt][fuelCategory].toFixed(2));
      rowThisDate.push(+(lbsByStartDate[dt][fuelCategory] / 2000).toFixed(2));
      rowThisDate.push(+(lbsByStartDate[dt][fuelCategory] / mwhByStartDate[dt][fuelCategory]).toFixed(2));
    });
    rows.push(rowThisDate);
  });

  useEffect(() => {
    const button = document.getElementById(Constants.DOWNLOAD_BUTTON_ID);
    if (button) {
      button.onclick = createDownloadFunction(appState, mapState, rows);
    }
  });

  // Below Bootstrap XL size, we make the data panel full width. It shouldn't
  // have scrolling overflow at those smaller sizes.
  let dataPanelStyle = {
    overflow: 'visible'
  };
  if (getBrowserWidth() >= 1200) {
    dataPanelStyle['overflow'] = 'scroll';
  }

  const showLoading = isError || isFetching;

  return (
    <Box sx={dataPanelStyle} className="data-view-container">
      <Box className="tour--time-series-chart">
        <TimeSeriesChart
          waiting={!paramsComplete}
          loading={showLoading}
          // Avoid flickering stale data while loading happens.
          seriesByFuelCategory={showLoading ? {} : seriesByFuelCategory}
        />
      </Box>
      <Box mt={2}>
        <SummaryPanel
          loading={showLoading || !paramsComplete}
          emissionFramework={appState.emissionFramework}
          pollutant={appState.pollutant}
          totalMwh={processedData?.totalGeneration}
          totalEmissions={processedData?.totalEmissions}
          emissionIntensity={processedData?.averageIntensity}
          startDate={appState.queryStartDate}
          endDate={appState.queryEndDate}
          mapRegions={Array.from(mapState.mapSelection).map(
            region => mapState.mapLevel === MapLevel.LRZs ? `LRZ ${region}` : region
          )}
        />
      </Box>
      {/* <hr></hr> */}
      <Box mt={2}>
        <FuelMixPanel
          waiting={!paramsComplete}
          loading={showLoading}
          // Avoid flickering stale data while loading happens.
          fuelMix={showLoading ? [] : processedData?.fuelMix || []}
          emissionMix={showLoading ? [] : processedData?.emissionMix || []}
          pollutant={appState.pollutant}
          emissionFramework={appState.emissionFramework}
          showChartTitles={true}
          showDownloadButtons={true}
          showGenerationMix={true}
          showEmissionMix={true}
        />
      </Box>
    </Box>
  );
}


export default DataView;

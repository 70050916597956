import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs, { OpUnitType } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { ICustomer, IUser } from 'api/user';
import * as Constants from 'constants/constants';
import { MAX_DAYS_OF_HOURLY_DATA, MAX_MONTHS_OF_DAILY_DATA } from 'constants/constants';
import {
  Duration,
  EmissionAdjustment,
  EmissionFramework,
  FuelCategory,
  FuelMapping,
  MapLevel,
  MonthOrSeason,
  Pollutant,
  Units
} from 'constants/enums';
import { tracker } from "utils/tracker";
import { last } from 'utils/utils';

dayjs.extend(utc);
dayjs.extend(timezone);



export interface IMapState {
  mapLevel: MapLevel
  mapSelection: Set<string>
  userEnteredSelection: boolean
}


export interface IEditMapSelectionPayload {
  editMode: 'overwrite' | 'update'
  mapLevel: MapLevel
  mapSelection: Set<string>
}


export interface IAppSlice {
  pollutant: Pollutant
  emissionFramework: EmissionFramework
  fuelCategories: Set<FuelCategory>
  queryStartDate: dayjs.Dayjs
  queryEndDate: dayjs.Dayjs
  timeResolution: Duration
  emissionAdjustment: EmissionAdjustment
  fuelMapping: FuelMapping
  monthOrSeason: MonthOrSeason
  units: Units

  // We store the history of map states in a stack. When a user enters a region
  // level, they're adding an item to the stack (extending the history). When
  // the user exits a region level, they're popping an item from the stack.
  mapHistory: IMapState[],
  receivedAuth: boolean, // True if we've requested auth and received a response, even if that response was an error
  currentUser: null | IUser
  currentCustomer: null | ICustomer
  regionSource: 'EIA' | 'ISO'
  dataResolution: '1h' | '5m'
};

export const HISTORICAL_START = dayjs.utc(`${Constants.EARLIEST_DATA_YEAR}-01-01T00:00:00`).startOf('year');
export const HISTORICAL_END = dayjs.utc(`${Constants.LATEST_HOURLY_OR_DAILY_DATA_YEAR}-12-31T00:00:00`).endOf('year');
export const PROJECTIONS_END = dayjs.utc(`${Constants.LATEST_PROJECTIONS_YEAR}-12-31T00:00:00`).endOf('year');


const initialState: IAppSlice = {
  pollutant: Pollutant.CO2,
  emissionFramework: EmissionFramework.Generated,
  fuelCategories: new Set<FuelCategory>([FuelCategory.All]),
  queryStartDate: HISTORICAL_START,
  queryEndDate: HISTORICAL_END,
  timeResolution: Duration.Year,
  emissionAdjustment: EmissionAdjustment.ForElectricity,
  fuelMapping: FuelMapping.Simple,
  monthOrSeason: MonthOrSeason.All,
  units: Units.Imperial,

  // Initial map state is an empty selection, viewing BAs.
  mapHistory: [{
    mapLevel: MapLevel.LARGE_BAs,
    mapSelection: new Set<string>(),
    userEnteredSelection: false,
  }],
  receivedAuth: false,
  currentCustomer: null,
  currentUser: null,
  regionSource: 'ISO',
  dataResolution: '5m',
};


const clampDate = (d: dayjs.Dayjs, minDate: dayjs.Dayjs, maxDate: dayjs.Dayjs) => {
  if (d > maxDate) {
    return maxDate;
  }
  if (d < minDate) {
    return minDate;
  }
  return d;
}


const limitTimeWindow = (state: IAppSlice, action: PayloadAction<Partial<IAppSlice>>) => {
  const timeResolutionAfterUpdate = action.payload.timeResolution || state.timeResolution;
  const isProjectionPage = window.location.pathname.endsWith('/projections');
  const isHistoricalPage = window.location.pathname.endsWith('/historical');

  const willViewHourlyData = timeResolutionAfterUpdate === Duration.Hour;
  const willViewDailyData = timeResolutionAfterUpdate === Duration.Day;

  // If the time resolution is being updated, we need to reset the time window!
  let minStartDate = dayjs.utc(`${Constants.EARLIEST_DATA_YEAR}-01-01T00:00:00`).startOf('year');
  let maxStartDate = dayjs.utc(`${Constants.LATEST_DATA_YEAR}-12-31T00:00:00`).endOf('year');

  const mapState = last(state.mapHistory);

  // If not viewing monthly, daily, or hourly data, and MISO is selected, the max start date
  // is the end of the RRA projections (EOY 2041).
  const onlyMisoSelected = (mapState.mapSelection.size === 1 && mapState.mapSelection.has('MISO')) ||
    mapState.mapLevel === MapLevel.LRZs;
  if (![Duration.Month, Duration.Day, Duration.Hour].includes(timeResolutionAfterUpdate) && onlyMisoSelected && isProjectionPage) {
    maxStartDate = dayjs.utc(`${Constants.LATEST_PROJECTIONS_YEAR}-12-31T00:00:00`).endOf('year');
  }
  if ([Duration.Day, Duration.Hour].includes(timeResolutionAfterUpdate)) {
    minStartDate = dayjs.utc(`${Constants.EARLIEST_HOURLY_OR_DAILY_DATA_YEAR}-01-01T00:00:00`);
    maxStartDate = dayjs.utc(`${Constants.LATEST_HOURLY_OR_DAILY_DATA_YEAR}-12-31T00:00:00`).endOf('year');
  }

  let minEndDate = action.payload.queryStartDate || state.queryStartDate;
  let maxEndDate = maxStartDate;

  // If the time resolution is being updated, we probably need to change the start and end date.
  // Some time resolutions have a limited display window, and we also need to truncate dates to
  // align with the new resolution.
  if (action.payload.timeResolution) {
    const dayjsTruncateResolution = {
      [Duration.Hour]: 'day',
      [Duration.Day]: 'day',
      [Duration.Month]: 'year',
      [Duration.Year]: 'year',
      [Duration.Subyear]: 'year'
    }[timeResolutionAfterUpdate];

    // If the current start date is not within the available range for this time resolution, go to
    // the start of the available data. When moving "up" a resolution (e.g day to month), we
    // truncate the start date so that it aligns with the coarser interval.
    action.payload.queryStartDate = dayjs.utc(action.payload.queryStartDate).startOf(dayjsTruncateResolution as OpUnitType) || minStartDate;
    if (action.payload.queryStartDate < minStartDate || action.payload.queryStartDate >= maxStartDate) {
      action.payload.queryStartDate = minStartDate;
    }

    // Make sure that a minimum amount of data will show.
    minEndDate = {
      [Duration.Day]: action.payload.queryStartDate.add(7, 'days'),
      [Duration.Hour]: action.payload.queryStartDate.add(1, 'days'),
      [Duration.Month]: action.payload.queryStartDate.endOf('year'),
      [Duration.Year]: action.payload.queryStartDate.add(3, 'year'),
      [Duration.Subyear]: action.payload.queryStartDate.add(2, 'year')
    }[timeResolutionAfterUpdate];

    // Make sure that the end date is within range.
    maxEndDate = {
      [Duration.Day]: action.payload.queryStartDate.add(Constants.MAX_MONTHS_OF_DAILY_DATA - 1, 'months'),
      [Duration.Hour]: action.payload.queryStartDate.add(Constants.MAX_DAYS_OF_HOURLY_DATA - 1, 'days'),
      [Duration.Month]: maxStartDate,
      [Duration.Year]: maxStartDate,
      [Duration.Subyear]: maxStartDate
    }[timeResolutionAfterUpdate];
  }

  const shouldClamp = isProjectionPage || isHistoricalPage;
  action.payload.queryStartDate = shouldClamp ? clampDate(action.payload.queryStartDate || state.queryStartDate, minStartDate, maxStartDate) : action.payload.queryStartDate || state.queryStartDate;
  action.payload.queryEndDate = shouldClamp ? clampDate(action.payload.queryEndDate || state.queryEndDate, minEndDate, maxEndDate) : action.payload.queryEndDate || state.queryEndDate;

  // Some logic to make sure that the user can't select a massive window of hourly data.
  if (willViewHourlyData) {
    const warning = `Only ${MAX_DAYS_OF_HOURLY_DATA} days of hourly data can be viewed at a time! Please choose a shorter time window.`;
    const queryStartDate = action.payload.queryStartDate || state.queryStartDate;
    const queryEndDate = action.payload.queryEndDate || state.queryEndDate;
    if (queryStartDate && queryEndDate &&
      Math.abs(queryEndDate.diff(queryStartDate, 'days')) > MAX_DAYS_OF_HOURLY_DATA) {
      alert(warning);
      // Reject the state update.
      return;
    }
  }

  // Some logic to make sure that the user can't select a massive window of hourly data.
  if (willViewDailyData) {
    const warning = `Only ${MAX_MONTHS_OF_DAILY_DATA} months of daily data can be viewed at a time! Please choose a shorter time window.`;
    const queryStartDate = action.payload.queryStartDate || state.queryStartDate;
    const queryEndDate = action.payload.queryEndDate || state.queryEndDate;
    if (queryStartDate && queryEndDate &&
      (Math.abs(queryEndDate.diff(queryStartDate, 'months')) + 1) > MAX_MONTHS_OF_DAILY_DATA) {
      alert(warning);
      // Reject the state update.
      return;
    }
  }

  Object.assign(state, action.payload);
}


const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // ---------------------------------------------------------------------------------------------
    updateAppState: (state, action: PayloadAction<Partial<IAppSlice>>) => {
      limitTimeWindow(state, action);
    },

    // ---------------------------------------------------------------------------------------------
    changeMapLevel: (state, action: PayloadAction<{ level: MapLevel, selectedRegion?: string }>) => {
      const newSelection = new Set<string>();
      if (action.payload.selectedRegion) {
        newSelection.add(action.payload.selectedRegion);
      }
      // Clear the map history and start from a clean state in the new mapLevel.
      state.mapHistory = [{
        mapLevel: action.payload.level,
        mapSelection: newSelection,
        userEnteredSelection: false,
      }];
    },

    // ---------------------------------------------------------------------------------------------
    editMapSelection: (state, action: PayloadAction<IEditMapSelectionPayload>) => {
      if (state.mapHistory.length === 0) {
        console.warn('[singularity] Tried to edit map selection with an empty history. Probably a bug.');
        return;
      }
      const isProjectionPage = window.location.pathname.endsWith('/projections');

      let editedMapState = last(state.mapHistory);

      // Check if the user is entering a new region level (e.g by clicking on a subregion).
      if (action.payload.mapLevel !== editedMapState.mapLevel) {
        state.mapHistory.push({
          mapLevel: action.payload.mapLevel,
          mapSelection: action.payload.mapSelection,
          userEnteredSelection: false,
        });
      } else {
        if (action.payload.editMode === 'overwrite') {
          // Replace the current selected set of regions.
          editedMapState.mapSelection = action.payload.mapSelection;
        } else if (action.payload.editMode === 'update') {
          // Merge the current set of regions with any new regions.
          editedMapState.mapSelection = new Set([...editedMapState.mapSelection, ...action.payload.mapSelection]);
        } else {
          console.warn(`[singularity] Received unknown editMode '${action.payload.editMode}'. Probably a bug.`);
        }
      }

      // If the user clicks on MISO, show them the RRA forecasts by default (out to 2041).
      let queryEndDate = state.queryEndDate;
      const isRealtimePage = window.location.pathname.endsWith('/realtime');
      const isMarginalPage = window.location.pathname.endsWith('/marginal');
      const onlyMisoSelected = (editedMapState.mapSelection.size === 1 && editedMapState.mapSelection.has('MISO')) && !isRealtimePage && !isMarginalPage;
      if (onlyMisoSelected) {
        if (![Duration.Month, Duration.Day, Duration.Hour].includes(state.timeResolution) && onlyMisoSelected && isProjectionPage) {
          queryEndDate = dayjs.utc(`${Constants.LATEST_PROJECTIONS_YEAR}-12-31T00:00:00`).endOf('year');
        }
      }

      limitTimeWindow(state, { type: 'app/UpdateAppState', payload: { queryStartDate: state.queryStartDate, queryEndDate } });
    },

    // ---------------------------------------------------------------------------------------------
    enterMapSelection: (state, action: PayloadAction<void>) => {
      if (state.mapHistory.length === 0 || last(state.mapHistory).mapSelection.size === 0) {
        console.warn('[singularity] Tried to enter an empty map selection. Probably a bug.')
        return;
      }
      if (last(state.mapHistory).userEnteredSelection) {
        console.warn('[singularity] User has already entered selection, ignoring.');
        return;
      }
      state.mapHistory.push({
        userEnteredSelection: true,
        mapLevel: last(state.mapHistory).mapLevel,
        mapSelection: new Set([...last(state.mapHistory).mapSelection])
      });
    },

    // ---------------------------------------------------------------------------------------------
    exitMapSelection: (state, action: PayloadAction<void>) => {
      if (state.mapHistory.length === 0) {
        console.warn('[singularity] Tried to exit with an empty map history. Probably a bug.')
        return;
      }
      // Exiting has three different effects, depending on the state:
      // (1) If the user was zoomed into a selection, zoom out by popping the zoomed state.
      // (2) If the user wasn't zoomed in, but had some regions selected, then exiting just clears their selection.
      // (3) If the user wasn't zoomed in and hadn't selected anything, then exiting pops a state to go up a level.
      if (last(state.mapHistory).userEnteredSelection) {
        if (state.mapHistory.length >= 2) {
          state.mapHistory.pop();
        } else {
          last(state.mapHistory).userEnteredSelection = false;
        }
      } else {
        if (last(state.mapHistory).mapSelection.size > 0) {
          last(state.mapHistory).mapSelection.clear();
        } else {
          // Make sure the history can never be empty!
          if (state.mapHistory.length >= 2) {
            state.mapHistory.pop();
          }
        }
      }
      last(state.mapHistory).mapSelection.clear();
    },

    receiveCustomerAndUser: (state, action: PayloadAction<{ user: null | IUser, customer: null | ICustomer }>) => {
      if (action.payload.user) {
        tracker.identify(action.payload.user);
      }
      state.receivedAuth = true;
      state.currentCustomer = action.payload.customer;
      state.currentUser = action.payload.user;
    },
  },
});


export default appSlice.reducer;


export const {
  updateAppState,
  changeMapLevel,
  editMapSelection,
  enterMapSelection,
  exitMapSelection,
  receiveCustomerAndUser,
} = appSlice.actions;

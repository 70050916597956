export const singularityPrimaryColor = '#3396a1';

export const fuelCategoryPalette: any = {
  // Fuel type used by MISO.
  storage: '#B2292E',
  storageDischarge: '#B2292E',
  storageCharge: '#B2292E',
  batteries: '#B2292E',

  nuclear: '#EE7623',
  wind: '#80BC00',
  hydro: '#54C0E8',
  solar: '#FFC844',

  natural_gas: '#0082CA',
  naturalGas: '#0082CA',
  oil: '#004D71',
  petroleum: '#004D71',
  coal: '#636569',

  other: '#A7A8AA',

  // Custom types (not included in MISO color palette).
  geothermal: '#FFC0CB',
  unknown: '#A7A8AA',
  waste: '#483C32',
  biomass: '#A0522D',
  fossil_and_biomass: '#A0522D',
  wood: '#D2B48C',
  refuse: '#000000',
  landfill_gas: '#004D71',
  demand_response: '#000000',
  demandResponse: '#000000',
  energy_efficiency: '#7cb5ec',
  energyEfficiency: '#7cb5ec',

  // Used by the fuel type selection.
  all_fuel_types: '#54b6ac',
  allFuelTypes: '#54b6ac',
  all_others: '#54b6ac',
  allOthers: '#54b6ac',
  all_renewables: '#80BC00',
  allRenewables: '#80BC00',
  all_fossil_fuels: '#000000',
  allFossilFuels: '#000000'
}

export const coolColorMap9 = [
  0, "#115f9a",
  1, "#1984c5",
  2, "#22a7f0",
  3, "#48b5c4",
  4, "#76c68f",
  5, "#a6d75b",
  6, "#c9e52f",
  7, "#d0ee11",
  8, "#d0f400"
]

export const rainbowColorMap10 = [
  0, "#9e0142",
  1, "#d53e4f",
  2, "#f46d43",
  3, "#fdae61",
  4, "#fee08b",
  5, "#e6f598",
  6, "#abdda4",
  7, "#3288bd",
  8, "#66c2a5",
  9, "#5e4fa2"
]

export const misoSubregionColorMap = [
  0, "#80bc00",
  1, "#ff9e18",
  2, "#0082ca"
]